import clsx from 'clsx'

import styles from './badges.module.scss'

enum SidePosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

const badgesData = [
  {
    txt: 'Нас рекомендуют',
    position: SidePosition.LEFT,
    colored: false,
  },
  {
    txt: 'Нам доверяют',
    position: SidePosition.RIGHT,
    colored: true,
  },
  {
    txt: 'К нам возвращаются',
    position: SidePosition.RIGHT,
    colored: false,
  },
]

const Badges = () => {
  return (
    <div className={styles.badgesBlock}>
      {badgesData.map((badge) => {
        return (
          <p
            key={badge.txt}
            className={clsx(styles.badge, {
              [styles.badgeLeft]: badge.position === SidePosition.LEFT,
              [styles.badgeRight]: badge.position === SidePosition.RIGHT,
              [styles.badgeColored]: badge.colored,
            })}
          >
            {badge.txt}
          </p>
        )
      })}
    </div>
  )
}

export default Badges
