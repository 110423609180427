import { useState } from 'react'
import clsx from 'clsx'

import Review from './review/review'
import Badges from './badges/badges'
import Button from 'components/parts/button/button'
import usePCWindow from 'hooks/usePCWindow'

import ButtonThemes from 'components/parts/button/themes'
import { ScreenSize } from 'constants/media-constanst'
import { reviewData } from './data'

import courseStyles from './../../search/courses-search/courses-search.module.scss'
import styles from './course-reviews.module.scss'

const CourseReviews = () => {
  const [isFullReviewsShowed, setIsFullReviewsShowed] = useState(false)
  const isMobile = usePCWindow(ScreenSize.mobile)

  const showFullReviews = () => setIsFullReviewsShowed(true)

  return (
    <div className={styles.container}>
      <div className={clsx(courseStyles.container, styles.headerContainer)}>
        <h2 className={courseStyles.sectionHeader}>
          С нами уже <span className={styles.outlined}>70 000</span> врачей, и вот что они говорят
          о “Medpoint”
        </h2>
      </div>
      <div className={styles.grid}>
        <div className={styles.row1}>
          <div className={styles.block1}>
            <Badges />
          </div>
          <Review {...reviewData.review2} modificator={styles.block2} />
        </div>

        <div
          className={clsx(styles.row2, {
            [styles.visible]: isFullReviewsShowed,
          })}
        >
          <Review {...reviewData.review3} modificator={styles.block3} />
          <Review {...reviewData.review4} modificator={styles.block4} />
        </div>

        <div className={styles.row3}>
          <Review {...reviewData.review5} modificator={styles.block5} />
        </div>
      </div>
      {!isFullReviewsShowed && isMobile && (
        <div className={styles.btnWrapper}>
          <Button theme={ButtonThemes.SECOND} modificator={styles.btn} onClick={showFullReviews}>
            Показать больше отзывов
          </Button>
        </div>
      )}
    </div>
  )
}

export default CourseReviews
