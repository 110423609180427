import { FC, ReactElement } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import clsx from 'clsx'

import ScrollBar from '../scroll-area/scroll-area'

import styles from './card-transition-area.module.scss'

const DEFAULT_TRANSITION_TIME = 1800

interface ICardTransitionArea {
  transition?: boolean
  OnlyBigScreens?: boolean
  noMargin?: boolean
  noPadding?: boolean
  scrollModificator?: string
  eventWrapperModificator?: string
  children: ReactElement[]
}

const CardTransitionArea: FC<ICardTransitionArea> = ({
  transition = false,
  OnlyBigScreens = false,
  noMargin = false,
  scrollModificator,
  eventWrapperModificator,
  children,
  noPadding,
}: ICardTransitionArea) => {
  const classNames = clsx(styles.eventCardWrapper, {
    [styles.onlyBigScreens]: OnlyBigScreens,
    [styles.noMargin]: noMargin,
    [styles.noPadding]: noPadding,
    [eventWrapperModificator as string]: eventWrapperModificator,
  })

  return (
    <ScrollBar
      modificator={clsx(styles.scrollAreaCards, {
        [scrollModificator as string]: scrollModificator,
      })}
    >
      {transition ? (
        <TransitionGroup className={classNames}>
          {children.map((child, i) => (
            <CSSTransition
              key={i}
              timeout={DEFAULT_TRANSITION_TIME}
              unmountOnExit
              classNames={{
                exit: styles.exit,
              }}
            >
              {child}
            </CSSTransition>
          ))}
        </TransitionGroup>
      ) : (
        <div className={classNames}>{children}</div>
      )}
    </ScrollBar>
  )
}

export default CardTransitionArea
