const reviewData = {
  review2: {
    name: 'Паньков А.Н.',
    speciality: 'врач-травматолог, Москва',
    review:
      'Спасибо за оперативность! Нравится не только тематика и форма изложения материалов вебинаров,( по моей специальности - травматология и ортопедия), но и оперативность направления кодов после просмотра вебинара! Постоянно рекомендую вашу школу своим коллегам.',
  },
  review3: {
    name: 'Гусова З.Р.',
    speciality: 'врач-эндокринолог, Ростов-на-Дону',
    review:
      'Спасибо за поддержку, открытость и доступность сайта для работы в свободное время! Очень приятна такая позиция организаторов.',
  },
  review4: {
    name: 'Данилова Т.С.',
    speciality: 'врач-невролог, Москва',
    review:
      'Огромное спасибо за интереснейшие и очень полезные лекции, прекрасных лекторов! Неоценимая помощь в клинической практике! Успехов в дальнейшей организации вебинаров!',
  },
  review5: {
    name: 'Берман В.Б.',
    speciality: 'врач-гастроэнтеролог, Екатеринбург',
    review:
      'Большое спасибо! Одно удовольствие смотреть ваши вебинары. Интересный материал, хорошие темы, отличные докладчики. И особенно меня восхищает, как четко у вас налажено оповещение о начисленных баллах НМО (в отличие от некоторых других порталов).',
  },
}

export { reviewData }
